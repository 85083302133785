@import "./_media-query-mixins.less";
@import "./_sizing-mixins.less";
@import "./_typography-mixins.less";
@import "./ax-breakpoints.less";

//Border Radius Simple -- to use: .border-radius(2rem) or .border-radius(2rem 2rem 0 0);
.border-radius(@radius: @ax-border-radius-m) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

// https://stackoverflow.com/questions/27447279/css-background-image-w-background-color-shows-artifact-border-in-chrome
// Needed to strip out the jaggies from Chrome since transforms on a parent can cause problems
.backface-cull-fix() {
  -webkit-backface-visibility: hidden;
  -webkit-backface-visibility: 1px;
}

.box-shadow(@box-shadow: none) {
  -webkit-box-shadow: @box-shadow;
  -moz-box-shadow: @box-shadow;
  -ms-box-shadow: @box-shadow;
  box-shadow: @box-shadow;
}

//Box Shadow BLACK -- to use: .box-shadow(5px, 5px, 6px, 0.3);
.box-shadow-black(@x: 0px, @y: 3px, @blur: 5px, @alpha: 0.5) {
  .box-shadow(@x @y @blur rgba(0, 0, 0, @alpha));
}

//Box Shadow WHITE -- to use: .box-shadow(5px, 5px, 6px, 0.3);
.box-shadow-white(@x: 0px, @y: 3px, @blur: 5px, @alpha: 0.5) {
  .box-shadow(@x @y @blur rgba(255, 255, 255, @alpha));
}

.text-shadow-highcharts() {
  // Add a11y shadow around labels that are inside columns (doesn't work as highcharts property)
  text-shadow: 0.1rem 0.1rem 0.1rem @ax-color-grey-70,
               0.1rem -0.1rem 0.1rem @ax-color-grey-70,
              -0.1rem -0.1rem 0.1rem @ax-color-grey-70,
              -0.1rem 0.1rem 0.1rem @ax-color-grey-70;
}

//Transition -- to use: .transition( all .2s );
.transition(...) {
  -webkit-transition: @arguments;
  -moz-transition: @arguments;
  -ms-transition: @arguments;
  -o-transition: @arguments;
  transition: @arguments;
}

//Transition property -- to use: .transition-property(left); or .transition-property(~"left, right");
.transition-property(@property) {
  -webkit-transition-property: @property;
  -moz-transition-property: @property;
  -ms-transition-property: @property;
  -o-transition-property: @property;
  transition-property: @property;
}

//Box-size: --you use: .box-sizing(border-box);
.box-sizing(@type: border-box) {
  -webkit-box-sizing: @type;
  -moz-box-sizing: @type;
  box-sizing: @type;
}

//Transform
.transform(@value) {
  -webkit-transform: @value;
  -moz-transform: @value;
  -ms-transform: @value;
  -o-transform: @value;
  transform: @value;
}


// Keyframe - animation transition
/* Use:
  .keyframes-mixin(keyFrameName, {
    0% { opacity: 0; }
    100% { opacity: 1; }
  });
*/
.keyframes-mixin(@name, @value) {
  @-webkit-keyframes @name { @value(); }
  @-moz-keyframes @name { @value(); }
  @-ms-keyframes @name { @value(); }
  @-o-keyframes @name { @value(); }
  @keyframes @name { @value(); }
}

// Animations
// Use: .animation(keyFrameName 1s linear 1s both);
.animation-mixin(@value) {
  -webkit-animation: @value;
  -moz-animation: @value;
  -ms-animation: @value;
  -o-animation: @value;
  animation: @value;
}

//Background Clip
.background-clip(@value) {
  -webkit-background-clip: @value; /* for Safari */
  background-clip: @value; /* for IE9+, Firefox 4+, Opera, Chrome */
}

//Opacity
.opacity(@opacity) {
  @ieOpacity: @opacity * 100;
  -moz-opacity: @opacity;
  -khtml-opacity: @opacity;
  opacity: @opacity;
}

//Filter
.filter(@value) {
  -webkit-filter: @value;
  filter: @value;
}

//Backdrop Filter
.backdrop-filter(@value) {
  -webkit-backdrop-filter: @value;
  backdrop-filter: @value;
}

//Vertical Gradient -- to use: .gradient(@startColor: @primary-bg, @endColor: #ececec);
.gradient(@startColor: @value, @endColor: @value) {
  background-color: @startColor;
  background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
  background: -webkit-linear-gradient(top, @startColor, @endColor);
  background: -moz-linear-gradient(top, @startColor, @endColor);
  background: -ms-linear-gradient(top, @startColor, @endColor);
  background: -o-linear-gradient(top, @startColor, @endColor);
}

.transparent-to-white-vertical-gradient {
  .gradient(@transparent-white, @white);
}

//Horizontal Gradient -- to use: .horizontal-gradient (@startColor: @primary-bg, @endColor: #ececec);
.horizontal-gradient(@startColor: @value, @endColor: @value, @startPosition: 90%, @endPosition: 100%) {
  background-color: @startColor;
  background: -webkit-gradient(linear, left top, right top, from(@startColor), to(@endColor));
  background: -webkit-linear-gradient(left, @startColor @startPosition, @endColor @endPosition);
  background: -moz-linear-gradient(left, @startColor @startPosition, @endColor @endPosition);
  background: -ms-linear-gradient(left, @startColor @startPosition, @endColor @endPosition);
  background: -o-linear-gradient(left, @startColor @startPosition, @endColor @endPosition);
}

//Appearance
.appearance(@value) {
  -webkit-appearance: @value;
  -moz-appearance: @value;
  appearance: @value;
}

.grey-top(@value: 5px) {
  background-color: @primary-bg;
  border: 1px solid @secondary-grey;
  .border-radius(@value @value 0 0);
}

.grey-top {
  .grey-top();
}

// Row Arrow
.row-chevron(@top, @right, @sm-top, @sm-right) {
  height: 1.6rem;
  line-height: 1.6rem;
  margin-top: -@ax-spacing-s;
  margin-right: @ax-spacing-xs;
  position: absolute;
  top: @top;
  right: @right;
  font-size: inherit;
  .screen-sm-max({top: @sm-top; right: @sm-right});
}

// TODO: Convert all instances of this mixin into .row-chevron-button()
.row-chevron-l(@top, @right, @sm-top, @sm-right) {
  height: 1.6rem;
  line-height: 1.6rem;
  margin-top: -@ax-spacing-s;
  margin-right: @ax-spacing-xs;
  position: absolute;
  top: @top;
  right: @right;
  font-size: 2.4rem;
  color: @ax-color-black;
  .screen-sm-max({top: @sm-top; right: @sm-right});
}

.row-chevron-button(@top, @right) {
  position: absolute;
  top: @top;
  right: @right;
}

.triangle(@direction, @size, @color) {
  .triangle(@direction, @size * 2, @size, @color);
}

.triangle(@direction, @width, @height, @color) {
  content: '';
  display: block;
  width: 0;
  height: 0;
  -moz-transform: scale(.9999);

  & when (@direction = up) {
    border-left: (@width / 2) solid transparent;
    border-right: (@width / 2) solid transparent;
    border-bottom: @height solid @color;
  }

  & when (@direction = down) {
    border-left: (@width / 2) solid transparent;
    border-right: (@width / 2) solid transparent;
    border-top: @height solid @color;
  }

  & when (@direction = left) {
    border-top: (@width / 2) solid @color;
    border-bottom: (@width / 2) solid @color;
    border-right: @height solid transparent;
  }

  & when (@direction = right) {
    border-top: (@width / 2) solid @color;
    border-bottom: (@width / 2) solid @color;
    border-left: @height solid transparent;
  }
}

.size(@size) {
  width: @size;
  height: @size;
}

.size(@width, @height) {
  width: @width;
  height: @height;
}

// Print mixins for page breaks.
.break-inside(@value: auto) {
  -webkit-column-break-inside: @value;
  page-break-inside: @value;
  break-inside: @value;
}

.break-before(@value: auto) {
  -webkit-column-break-before: @value;
  page-break-before: @value;
  break-before: @value;
}

.break-after(@value: auto) {
  -webkit-column-break-after: @value;
  page-break-after: @value;
  break-after: @value;
}

//Clearfix Mixin
.clearfix() {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
}

.off-screen {
  position: absolute;
  .size(.1rem);
  overflow: hidden;
  margin: -0.1rem;
  clip: rect(.1rem, .1rem, .1rem, .1rem);

  &:focus {
    position: static;
    overflow: auto;
    height: auto;
    width: auto;
    clip: auto;
  }

  .lt-ie10 & {
    display: none;
  }
}

.off-screen-static {
  position: absolute;
  .size(.1rem);
  margin: -0.1rem;
  overflow: hidden;
  clip: rect(.1rem, .1rem, .1rem, .1rem);

  .lt-ie10 & {
    display: none;
  }
}

.sr-only {
  // Screen reader only - hidden from display, but still accessible
  .size(.1rem);
  border: 0;
  clip: rect(.1rem, .1rem, .1rem, .1rem);
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.offscreen-nav-link {
  &:focus {
    background-color: @white;
    border: 2px solid @primary-blue;
    color: @black;
    height: auto;
    line-height: normal;
    margin: auto;
    position: absolute;
    text-align: center;
    width: auto;
    z-index: 999;
  }
}

.table-helper {
  display: table;
}

.table-row-helper {
  display: table-row;
}

.table-cell-helper {
  display: table-cell;
}

.printable-background-mixin(@color: black) {
  background-color: @color;
  -webkit-print-color-adjust: exact;
  box-shadow: inset 0 0 0 500px @color;
}

.ax-focus-keyboard(@inset: ~'') {
  // Focus for keyboard accessibility only (Stronger box shadow)
  .box-shadow(@inset 0 0 .2rem .2rem @ax-color-blue-60);
  border-color: @ax-color-blue-60;
}

.ax-focus-mouse(@inset: ~'') {
  // Focus visible for mouse/keyboard (Subtle box shadow from UI Kit)
  .box-shadow(@inset 0 0 .2rem .2rem fade(@ax-color-blue-60, 25%));
  border-color: @ax-color-blue-60;
}

.ax-focus-input() {
  // 2px solid outline, as per UI Kit
  box-shadow: 0 0 0 .1rem @ax-color-blue-60;
  border-color: @ax-color-blue-60;
  outline: 'none' // Remove legacy browser default outline
}

.ax-focus-ring() {
  outline: var(--ax-focus-ring-style);
  outline-offset: var(--ax-focus-ring-offset);
}

// this prevents an element from popping up a context menu in iOS Safari, when you long-tap on it
.prevent-ios-tap-context() {
  pointer-events: none; // this one is generic, to prevent touch events on the element
  -webkit-touch-callout: none; // this one is specific to iOS Safari
}

.thick-gray-button {
  min-width: @ax-spacing-xxl;
  min-height:  @ax-spacing-xxl;
  background-color: @ax-color-grey-20;
  border: 1px solid @ax-color-grey-30;
  color: @secondary-text-color;
  &:hover {
    color: @nav-hover;
  }

  .border-radius(@ax-spacing-xs);

  &.focus-visible:focus {
    .ax-focus-keyboard();
  }
}

// Fade in - Gobal animation keyframes
.keyframes-mixin(fadein, {
  0% { opacity: 0; }
  100% { opacity: 1; }
});

.round-avatar() {
  @size: 3.5rem;
  .size(@size);
  line-height: @size;
  background-color: @ax-color-grey-30;
  .border-radius(50%);
  text-align: center;
  margin-top: @ax-spacing-xs;
  color: @white;
  float: left;
}

// Simplest way to prevent an parent/element from having their vertical margin's collapse automatically. All other
// solutions used styles that had much greater impact whereas using 0.05px allowed for disabling it without causing
// any visual effects.
.no-margin-collapse() {
  padding: 0.05px;
}

.rtl-only(@rules) {
  [dir=rtl] & {
    @rules();
  }
}

.all-dir(@rules) {
  [dir] & {
    @rules();
  }
}

.closed-background() {
  background-image: url('@common/assets/images/down-arrow.gif');
  background-position-x: 95%;
  background-position-y: center;
  background-position: right 8px center;
  background-repeat: no-repeat;
}

.open-background() {
  background-image: url('@common/assets/images/up-arrow.gif');
  background-position-x: 95%;
  background-position-y: center;
  background-position: right 8px center;
  background-repeat: no-repeat;
}

.truncate-block(@lines: 1) when (@lines = 1) {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden !important;
}
.truncate-block(@lines: 1) when (@lines > 1) {
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: @lines;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
}
.truncate-2 {
  .truncate-block(2);
}
.truncate-3 {
  .truncate-block(3);
}
.truncate-4 {
  .truncate-block(4);
}
